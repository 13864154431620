import React from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'

import { buttonClassNames } from '../Button/Button'

interface ContactField {
  name: string,
  type: string,
  label: string,
  placeholder: string
}

export default function HomepageContact(): JSX.Element {
  const contactFields: ContactField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      placeholder: 'Your Name',
    },
    {
      name: 'company',
      type: 'text',
      label: 'Company',
      placeholder: 'Your Company',
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      placeholder: 'Email Address'
    },
    {
      name: 'phone',
      type: 'text',
      label: 'Phone Number',
      placeholder: 'Phone Number'
    }
  ]

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault()

    const target = event.target as typeof event.target & {
      getAttribute: Function,
      name: { value: string },
      company: { value: string },
      email: { value: string },
      phone: { value: string }
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        'form-name': 'contact',
        name: target.name.value,
        company: target.company.value,
        email: target.email.value,
        phone: target.phone.value
      }).toString()
    })
      .then(() => navigate('/success/'))
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <section
      className='bg-green py-24'
      id='book-a-demo'
    >
      <div className='max-w-6xl mx-auto px-4'>
        <div className='grid sm:grid-cols-2 gap-5 mb-12'>
          <h2 className='text-4xl tracking-tighter'><span className='text-white'>Book a Demo</span> for The Draw</h2>
          <div>
            <p>If you'd like to know more about The Draw and see a live demo, fill out the form below and one of the team will reach out to you shortly.</p>
          </div>
        </div>
        <form
          name='contact'
          method='POST'
          data-netlify='true'
          onSubmit={handleSubmit}
        >
          <input type='hidden' name='form-name' value='contact' />
          <div className='grid sm:grid-cols-2 gap-x-5 gap-y-10 mb-10'>
            {contactFields.map((field, fieldIndex) => {
              return (
                <div
                  key={fieldIndex}
                >
                  <label
                    htmlFor={`contact-${field.name}`}
                    className='block font-inter text-sm text-white mb-3'
                  >
                    {field.label}*
                  </label>
                  <input
                    id={`contact-${field.name}`}
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    required
                    className='block w-full border-2 border-black shadow-hard px-6 py-4 focus:bg-black focus:text-white focus:border-white focus:outline-none rounded-none'
                  />
                </div>
              )
            })}
          </div>
          <div>
            <button
              className={classNames(buttonClassNames, 'block w-full sm:w-1/2 mx-auto h-[3.75rem]')}
              type='submit'
            >
              Book A Demo
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}