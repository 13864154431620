import React from 'react'
import classNames from 'classnames'

function getSrc(
  image: string,
  maintainAspectRatio: boolean,
  width: number,
  height: number
): string {
  // https://ucarecdn.com/961c7a96-cb8b-437f-9415-512f2239db67/-/format/auto/-/srgb/fast/-/resize/1764x/-/quality/lightest/882.jpg
  if (maintainAspectRatio) {
    return `${image}-/format/auto/-/srgb/fast/-/quality/normal/-/resize/${width}x${height}/${width}.jpg`
  }

  return `${image}-/format/auto/-/srgb/fast/-/quality/normal/-/resize/${width}x/${width}.jpg`
}

function getSrcSet(
  image: string,
  maintainAspectRatio: boolean,
  width: number,
  height: number,
  minWidth: number,
  step: number
): string {
  const aspect = height / width
  const srcSet = []
  for (let srcWidth = minWidth; srcWidth <= width; srcWidth = srcWidth + step) {
    if (maintainAspectRatio) {
      srcSet.push(`${image}-/format/auto/-/srgb/fast/-/quality/normal/-/resize/${srcWidth}x${Math.round(srcWidth * aspect)}/${srcWidth}.jpg ${srcWidth}w`)
    } else {
      srcSet.push(`${image}-/format/auto/-/srgb/fast/-/quality/normal/-/resize/${srcWidth}x/${srcWidth}.jpg ${srcWidth}w`)
    }
  }

  return srcSet.join(', ')
}

interface ImageProps {
  image: string,
  alt: string,
  className?: string,
  width?: number,
  height?: number,
  minWidth?: number
  step?: number,
  maintainAspectRatio?: boolean
}

export default function Image(props: ImageProps): JSX.Element {
  const {
    image,
    alt,
    className = 'w-full h-auto',
    width = 1500,
    height = 1000,
    minWidth = 300,
    step = 100,
    maintainAspectRatio = false
  } = props

  return (
    <img
      src={`data:image/svg+xml,%3Csvg width='${width}' height='${height}' viewBox='0 0 ${width} ${height}' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E`}
      data-src={getSrc(image, maintainAspectRatio, width, height)}
      data-srcset={getSrcSet(image, maintainAspectRatio, width, height, minWidth, step)}
      className={classNames('lazyload', className)}
      loading='lazy'
      alt={alt}
      width={width}
      height={height}
      data-sizes='auto'
    />
  )
}
