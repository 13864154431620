import React from 'react'

import BookADemo from '../Button/BookADemo'
import Link from '../Link/Link'

import scrollToElement from '../../utils/scrollToElement'

import HeroBg from '../../assets/img/hero-bg.svg'
import IconTriangleDownGreen from '../../assets/img/icon-triangle-down-green.svg'

export default function HomepageHero(): JSX.Element {
  return (
    <section className='bg-green relative md:h-[50rem]'>
      <div className='max-w-6xl mx-auto px-4 relative z-10'>
        <div className='max-w-2xl space-y-8 pt-32 pb-48 sm:pb-60 md:pb-0'>
          <h1 className='text-4xl md:text-6xl tracking-tighter'>Efficient, secure <span className='text-white'>lottery platform</span> for high-profile sneaker releases.</h1>
          <h2>Designed to efficiently facilitate high profile in-store sneaker <br className='hidden sm:inline' />raffles, The Draw is a secure And scalable digital lottery platform.</h2>
          <div className='flex items-center space-x-4 sm:space-x-12'>
            <BookADemo />
            <Link
              to='#features'
              className='inline-flex items-center space-x-2 font-inter text-sm hover:text-white transition-colors duration-300 ease-smooth group'
              onClick={(event) => scrollToElement(event, '#features')}
            >
              <span className='underline'>
                View Features
              </span>
              <span className='inline-flex justify-center items-center border border-black rounded-full w-[1.375rem] h-[1.375rem] group-hover:bg-white group-hover:border-white transition-colors duration-300 ease-smooth'>
                <IconTriangleDownGreen className='w-2 fill-black transition-colors duration-300 ease-smooth' />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className='absolute left-0 bottom-0 w-full overflow-hidden h-[25rem] sm:h-[39rem] md:h-[67rem] pointer-events-none'>
        <HeroBg className='w-[44rem] sm:w-[70rem] md:w-[120rem] absolute left-[50%] transform -translate-x-1/2 translate-y-[8rem] md:translate-y-[20rem]' />
      </div>
    </section>
  )
}