import React from 'react'
import classNames from 'classnames'

import Image from '../Image/Image'
import Link from '../Link/Link'

import scrollToElement from '../../utils/scrollToElement'

interface Feature {
  title: string,
  body: React.ReactElement,
  image: string,
}

export default function HomepageFeatures(): JSX.Element {
  const features: Feature[] = [
    {
      title: 'Feed',
      body: <p>Upon accessing The Draw, consumers will be faced with a dynamic scrollable home-feed, showcasing your upcoming, live, and past raffles – there’s also optional scope here to house additional content to inspire and engage your audience.</p>,
      image: 'https://ucarecdn.com/007b0a91-a93f-4806-8f98-43ce043ff867/'
    },
    {
      title: 'Verification',
      body: <p>To enter a raffle, consumers must first register for an account – a process which requires both email and SMS verification, reducing the opportunity for multiple account creations per customer. The data taken in this process can be used to generate raffle reports, offering insights into the gender split, most popular shoe size and more. Individuals can only enter one shoe size per raffle – again making life much harder for resellers.</p>,
      image: 'https://ucarecdn.com/396315c2-0812-4a2e-9a65-dc454a4e61a9/'
    },
    {
      title: 'Notifications',
      body: <>
        <p>Upon completing the entry process, consumers will receive an instant email confirmation. Then, when the time comes, if they’re unlucky they’ll receive an email to notify them that they’ve lost. But if they’re lucky they’ll receive an SMS to let them know they’ve won, as well as an email complete with a custom image – displaying the customer’s name and the raffle details – designed to be shared on socials.</p>
        <p>Winners are invited to reserve a collection slot via our online booking system; created to ensure that high-heat releases can be staggered with ease throughout the collection period – no more crowds, queuing or admin nightmares.</p>
      </>,
      image: 'https://ucarecdn.com/f289d643-8f97-427d-82c3-aa1407f41eaf/'
    },
    {
      title: 'Administration',
      body: <>
        <p>The Draw is equipped with a whole suite of features to make the raffle process as secure and efficient for admins as it is for users – with the addition of insight-mining tools to gain a greater understanding of your customer.</p>
        <p>Admin access is gained via tiered logins, enabling team members to have varying levels of access to the platform depending on their role. Once logged in, admins will land on a live dashboard, where widgets will display the current raffle data relevant to their login credentials.</p>
      </>,
      image: 'https://ucarecdn.com/a5bc20d2-06ef-48b6-9aac-581757daa498/'
    },
    {
      title: 'Customer Insights',
      body: <p>When it comes to unearthing insights into your raffle consumers’ behaviours, full user journey tracking is applied – meaning The Draw serves not only as a retail platform, but as a valuable data resource. By default, we use Google Analytics for this – but other systems can be substituted as required. All the accumulated customer information is searchable and filterable, and raffle reports are available for download at any point in the process.</p>,
      image: 'https://ucarecdn.com/54d58f13-85cb-4d58-9a8b-76952e7ff68a/'
    },
    {
      title: 'Security',
      body: <p>The Draw employs multiple layers of defence to dodge, deter and do away with both resellers and bots. Beyond the consumer facing verification process, we use ReCaptcha and a shadow banning system, meaning fraudulent accounts don’t even know they’ve been barred. Known resellers are automatically banned by email address and/or phone number – or even IP address. Additionally, admins can both ban and un-ban specific customers from the platform.</p>,
      image: 'https://ucarecdn.com/3f97671d-b0fa-41d2-b650-672587d90fca/'
    }
  ]

  return (
    <section
      className='bg-slate py-24'
      id='features'
    >
      <div className='max-w-6xl mx-auto px-4 space-y-20'>
        {features.map((feature, featureIndex) => {
          return (
            <div
              key={featureIndex}
              className='grid md:grid-cols-2 gap-x-5 items-center'
            >
              <div className='border-3 border-black shadow-hard mb-10 md:mb-0'>
                <Image
                  image={feature.image}
                  alt={feature.title}
                />
              </div>
              <div
                className={classNames('space-y-6', {
                  'md:text-right md:pl-20': (featureIndex % 2 !== 0),
                  'md:pr-20 md:order-first': (featureIndex % 2 === 0)
                })}
              >
                <h2 className='text-4xl tracking-tighter'>{feature.title}</h2>
                <div className='space-y-4'>
                  {feature.body}
                </div>
                <div>
                  <Link
                    to='#features'
                    className='font-inter text-green underline'
                    onClick={(event) => scrollToElement(event, '#book-a-demo')}
                  >
                    Book A Demo
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}