import React from 'react'
import { PageProps } from 'gatsby'

import Hero from '../components/Homepage/Hero'
import Features from '../components/Homepage/Features'
import Contact from '../components/Homepage/Contact'

export default function IndexPage(props: PageProps): JSX.Element {
  return (
    <>
      <Hero />
      <Features />
      <Contact />
    </>
  )
}
